/* eslint-disable import/no-extraneous-dependencies */
import {
  shortISOString,
  timeDisplayFactory,
  timeFormat,
} from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';

const SmartTimeWrapper = styled.time`
  color: #777777;
`;

interface SmartTimeProps {
  second: number;
  nowMs?: number;
  format: string;
}

export const SmartTime = ({ second, nowMs, format }: SmartTimeProps) => {
  const ms = second * 1000;

  return (
    <SmartTimeWrapper dateTime={shortISOString(ms)}>
      {format
        ? timeFormat(ms, format)
        : timeDisplayFactory(ms, nowMs as number)}
    </SmartTimeWrapper>
  );
};

export default SmartTime;

'use client';
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
// TODO: 要移除這個 prop-types 套件，改成新版的元件處理
import PropTypes from 'prop-types';

export const Colors = {
  Positive: '224, 63, 25',
  Negative: '32, 131, 6',
  Default: '221,221,221',
};

/*
        session: [[1585008000, 1585017000], [1585020600, 1585029600]]
                    |                 |     /                /
                    |                 |    /                /
                    |                 |   /                /
                    |                 |  /                /
  value             |                 | /                /
  18092.35 ..... (0,0) ------------------------------ (width, 0)
                    |                                      |
                    |                                      |
                    |             SVG CHART                |
                    |                                      |
                    |                                      |
  17402.93 ..... (0,height) ------------------------- (width,height)
*/
export const getGenerator = (pd, w, h, minX, maxX, minY, maxY, session, marginTop) => {
  const desession = x => {
    let res = x;

    if (session && session.length > 1) {
      for (let i = 0; i < session.length - 1; i++) {
        const s = session[i][1];
        const ns = session[i + 1][0];

        if (x >= s && ns && x < ns) {
          res = s;

          break;
        } else if (x >= s && ns) {
          res = x - (ns - s);
        }
      }
    }

    return res;
  };

  // memoize constant computation results as much as possible
  const xr = desession(maxX) - minX;
  const yr = maxY - minY;
  const c1 = w - pd * 2;
  const c2 = pd + marginTop;
  const c3 = h - pd * 2 - marginTop;

  return {
    getX: d => pd + c1 * ((desession(d) - minX) / xr),
    getY: d => c2 + (yr === 0 ? 0 : c3 * (1 - (d - minY) / yr)),
    xr,
    yr,
    maxX,
    maxY,
    minX,
    minY,
  };
};

const clamp = (u, b, n) => Math.max(Math.min(n, u), b);

let id = 0;

/**
 * Pad zero until length is 2
 */
function pad2(num) {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

function attr(el, attrs) {
  // eslint-disable-next-line
  for (const i in attrs) {
    el.setAttribute(i, attrs[i]);
  }
}

export default class SVGChart extends React.Component {
  static propTypes = {
    updateKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tooltipByDate: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    t: PropTypes.arrayOf(PropTypes.number).isRequired,
    c: PropTypes.arrayOf(PropTypes.number).isRequired,
    session: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    colorPositive: PropTypes.string,
    colorNegative: PropTypes.string,
    padding: PropTypes.number,
    base: PropTypes.number,
    marginTop: PropTypes.number,
    highlightLastPoint: PropTypes.bool,
    shouldPreventHoverEvent: PropTypes.bool,
    strokeWidth: PropTypes.number
  };

  // TODO: 改寫，新版不再支援 defaultProps 屬性了
  static defaultProps = {
    colorPositive: Colors.Positive,
    colorNegative: Colors.Negative,
    padding: 4,
    base: 0,
    marginTop: 10,
    highlightLastPoint: false,
    shouldPreventHoverEvent: false,
    strokeWidth: 1,
  };

  /**
   * This function computes a plot line by given plot data and configs, separate plot line computation
   * function and DOM manipulation function make it easier to write unit test.
   */
  static plotRenderer = plotData => {
    const { padding, t, c, session, width, height, base, marginTop } = plotData;

    // find lower and upper bound of y-axis
    const [yMin, yMax] = [Math.min.apply(null, c), Math.max.apply(null, c)];

    let xMax;
    let xMin;
    let xProjection;

    // when sessions are given, use session's first and last element as range of x-axis
    if (session && session.length > 0) {
      xMax = session[session.length - 1][1];
      xMin = session[0][0];
    } else {
      xMax = t.slice(-1)[0];
      xMin = t[0];
    }

    // generator function will be reused til next update, which is a pair of functions
    // that transform given t and c values to X, Y point on the screen
    const generator = getGenerator(
      padding,
      width,
      height,
      xMin,
      xMax,
      Math.min(yMin, base),
      Math.max(yMax, base),
      session,
      marginTop
    );
    const { getX, getY, xr, yr } = generator;

    let baselineX = -1;
    let baselineY = -1;

    // this is a number which represents the minimal change in t which will results in
    // 1px change on the scree, can be use to boost SVG size and render speed
    const minDiffX = Math.abs(xr) / width;
    const minDiffY = Math.abs(yr) / width;

    // a cursor records last calculated X position, we use this value to close the path of plot area
    // and faster hover point computation.
    xProjection = -1;

    // since the data feed might give us data not inside sessions
    // we should check the index of where the chart series should start with
    let i = 0;

    // skip those points with timestamp less than minimum x value (usually mean start of daily session)
    if (t[i] < xMin) {
      for (; i < t.length; i++) {
        if (t[i] >= xMin) {
          break;
        }
      }
    }

    // move cursor to point of first valid record
    // eslint-disable-next-line
    let line = `M ${padding} ${getY(c[i++])}`;

    for (; i < t.length; i++) {
      const dx = t[i];
      const dy = c[i];

      // skip the points which results in change less than 1pt (optimization)
      if (i !== c.length - 1 && Math.abs(dx - baselineX) < minDiffX && Math.abs(dy - baselineY) < minDiffY) {
        // eslint-disable-next-line
        continue;
      }

      baselineX = dx;
      baselineY = dy;
      const x = getX(dx);
      const y = getY(dy);

      line += ` L ${x} ${y}`;

      if (x > xProjection) {
        xProjection = x;
      }
    }

    let endPoint;

    if (t && t.length && c && c.length) {
      endPoint = {
        x: getX(t.slice(-1)[0]),
        y: getY(c.slice(-1)[0]),
      };
    }

    return {
      line,
      xProjection,
      generator,
      endPoint,
      baseY: getY(0) > height ? height - padding : getY(0),
    };
  };

  /**
   * The function computes where to show the snapped point from given screen point
   * and render props.
   */
  static pointSnapping = args => {
    const { generator, screenPos, configs } = args;
    const { getX, getY, minX, maxX } = generator;
    const { base, width, t } = configs;

    const snapToBaseline = Math.abs(getY(base) - screenPos.y) < 3;
    const isSnapToEnd = screenPos.x > width / 2;

    let index = -1;
    let min = screenPos.x;

    for (let i = 0; i < t.length; i++) {
      const dist = Math.abs(screenPos.x - getX(t[i]));

      if (i - index > 3 && index > 0) {
        break;
      }
      if (dist < min) {
        index = i;
        min = dist;
      }
    }

    const timeStamp = t[index];
    const outOfRange = timeStamp > maxX || timeStamp < minX;

    // do nothing if data is invalid or out-of-range
    if (!timeStamp) {
      index = -1;
    }

    return {
      index,
      outOfRange,
      snapToBaseline,
      isSnapToEnd,
    };
  };

  constructor(props) {
    super(props);

    this.mouseOver = false;
    this.plotAreaBoundX = -1;

    // lot of refs here, since we're going to use native call to repaint as it's more performance
    this.svg = null;
    this.plotUp = null;
    this.plotDown = null;
    this.vline = null;
    this.plotAreaUp = null;
    this.plotAreaDown = null;
    this.dot = null;
    this.dot2 = null;
    this.endDot = null;
    this.endDot2 = null;
    this.tooltip = null;
    this.baseline = null;
    this.rectUp = null;
    this.rectDown = null;
    this.mask = null;
    this.tooltipTime = null;

    this.setSvg = e => {
      this.svg = e;
    };
    this.setPlotUp = e => {
      this.plotUp = e;
    };
    this.setPlotDown = e => {
      this.plotDown = e;
    };
    this.setVline = e => {
      this.vline = e;
    };
    this.setPlotAreaUp = e => {
      this.plotAreaUp = e;
    };
    this.setPlotAreaDown = e => {
      this.plotAreaDown = e;
    };
    this.setDot = e => {
      this.dot = e;
    };
    this.setDot2 = e => {
      this.dot2 = e;
    };
    this.setEndDot = e => {
      this.endDot = e;
    };
    this.setEndDot2 = e => {
      this.endDot2 = e;
    };
    this.setTooltip = e => {
      this.tooltip = e;
    };
    this.setBaseline = e => {
      this.baseline = e;
    };
    this.setRectUp = e => {
      this.rectUp = e;
    };
    this.setRectDown = e => {
      this.rectDown = e;
    };
    this.setMask = e => {
      this.mask = e;
    };
    this.setTooltipTime = e => {
      this.tooltipTime = e;
    };

    // eslint-disable-next-line
    this.uniqueIndex = id++;
    this.uniqueId = `_auneSVGChart-${this.uniqueIndex}`;

    this.state = {
      generator: null,
      isEmpty: true,
      renderKey: -1,
    };

    this.refMap = null;
  }

  componentDidMount() {
    if (this.props.updateKey) {
      this.initChartSVG();
    }
  }

  shouldComponentUpdate = nextProps => {
    // use props.updateKey to control if the chart will update
    return nextProps.updateKey && (nextProps.updateKey === -1 || this.props.updateKey !== nextProps.updateKey);
  };

  componentDidUpdate = () => {
    this.initChartSVG();
  };

  initChartSVG = () => {
    this.refMap = [
      'svg',
      'plotUp',
      'plotDown',
      'vline',
      'plotAreaUp',
      'plotAreaDown',
      'dot',
      'dot2',
      'endDot',
      'endDot2',
      'tooltip',
      'baseline',
      'rectUp',
      'rectDown',
      'mask',
      'tooltipTime',
    ].reduce((p, c) => {
      // eslint-disable-next-line
      p[c] = this[c];

      return p;
    }, {});

    this.updateChartSVG();
  };

  // heavy method which renders SVG strings for the charts
  updateChartSVG = () => {
    const {
      t,
      c,
      width,
      height,
      padding,
      base,
      highlightLastPoint,
      colorPositive,
      colorNegative,
      session,
      marginTop,
    } = this.props;

    if (!t || !c || !Array.isArray(t) || !Array.isArray(c) || !this.refMap || typeof base !== 'number') {
      return;
    }

    const { plotUp, plotDown, plotAreaUp, plotAreaDown, baseline, endDot, endDot2, rectUp, rectDown } = this.refMap;

    // this is where the chart polyline created, no painting happens
    const { line, xProjection, generator, endPoint, baseY } = SVGChart.plotRenderer({
      padding,
      t,
      c,
      session,
      width,
      height,
      base,
      marginTop,
    });
    const avg = generator.getY(base);

    this.plotAreaBoundX = xProjection;

    let dotColor = Colors.Default;

    if (endPoint && endPoint.y < avg) {
      dotColor = colorPositive;
    } else if (endPoint && endPoint.y > avg) {
      dotColor = colorNegative;
    }

    // paint is happening here
    plotUp.setAttribute('d', line);
    plotDown.setAttribute('d', line);
    if (this.plotAreaBoundX > 0) {
      // d of area will be simply closing the line
      plotAreaUp.setAttribute('d', `${line} L ${this.plotAreaBoundX} ${baseY} L ${padding} ${baseY} Z`);
      plotAreaDown.setAttribute('d', `${line} L ${this.plotAreaBoundX} 0 L 0 0 Z`);
    } else {
      plotAreaUp.removeAttribute('d');
      plotAreaDown.removeAttribute('d');
    }

    // highlight last point
    if (highlightLastPoint && endPoint) {
      endDot.setAttribute('fill', `rgb(${dotColor})`);
      attr(endDot, { transform: `translate(${endPoint.x}, ${endPoint.y})` });
      endDot.setAttribute('r', '3');

      endDot2.setAttribute('fill', `rgba(${dotColor}, 0.2)`);
      attr(endDot2, { transform: `translate(${endPoint.x}, ${endPoint.y})` });
      endDot2.setAttribute('r', '10');
    } else {
      endDot.setAttribute('r', '0');
      endDot2.setAttribute('r', '0');
    }

    // no points to draw, draw baseline and update generator
    baseline.setAttribute('d', `M ${padding} ${avg} L ${width - padding * 2} ${avg}`);
    rectUp.setAttribute('height', `${avg}`);
    rectDown.setAttribute('y', `${avg}`);
    rectDown.setAttribute('height', `${height - avg}`);

    this.setState({
      generator,
      isEmpty: false,
      renderKey: this.props.updateKey,
    });
  };

  handleMouseLeave = () => {
    const { shouldPreventHoverEvent } = this.props;

    if (shouldPreventHoverEvent) {
      return;
    }
    this.mouseOver = false;
    this.performHoverAnimation(-1);
  };

  handleMouseMove = e => {
    const { shouldPreventHoverEvent } = this.props;

    if (!this.refMap || shouldPreventHoverEvent) {
      return;
    }

    const { generator } = this.state;
    const { padding, width } = this.props;
    const { svg, tooltip, tooltipTime } = this.refMap;

    const matrix = svg.getScreenCTM();
    const point = svg.createSVGPoint();

    point.x = e.clientX;
    point.y = e.clientY;

    if (matrix && tooltip && generator && tooltipTime) {
      const screenPos = point.matrixTransform(matrix.inverse());

      if (screenPos.x >= width - padding || screenPos.x <= padding) {
        return;
      }

      const { index, outOfRange, snapToBaseline, isSnapToEnd } = SVGChart.pointSnapping({
        generator,
        screenPos,
        configs: this.props,
      });

      if (outOfRange && !snapToBaseline) {
        return;
      }

      this.renderTooltipAtIndex(index, snapToBaseline, isSnapToEnd);
    }
  };

  performHoverAnimation = rate => {
    if (this.state.isEmpty || !this.refMap) {
      return;
    }

    const { tooltip, tooltipTime, dot, dot2, plotUp, plotDown, vline } = this.refMap;

    const display = rate > 0 ? 'block' : 'none';
    let frames = 0;

    const animate = () => {
      plotUp.style.strokeWidth = `${+(plotUp.style.strokeWidth || 1) + rate * (1 / 10)}`;
      plotDown.style.strokeWidth = `${+(plotDown.style.strokeWidth || 1) + rate * (1 / 10)}`;

      const cr = +(dot.getAttribute('r') || 0);

      dot.setAttribute('r', `${clamp(4, 0, cr + rate * (4 / 10))}`);

      const cor = +(dot2.getAttribute('r') || 0);

      dot2.setAttribute('r', `${clamp(12, 0, cor + rate * (12 / 10))}`);

      // eslint-disable-next-line
      if (++frames < 10) {
        requestAnimationFrame(animate);
      } else {
        // hard reset plot style
        if (rate < 0) {
          plotUp.style.strokeWidth = '1';
          plotDown.style.strokeWidth = '1';
        }
        // eslint-disable-next-line
        [dot, tooltip, tooltipTime, dot2, vline].forEach(s => (s.style.display = display));
      }
    };

    animate();
  };

  renderTooltipAtIndex = (index, snapToBaseline, isSnapToEnd) => {
    const { tooltipByDate } = this.props;
    const { generator } = this.state;

    if (!this.refMap || !generator) {
      return;
    }
    const { getX, getY } = generator;
    const { padding, t, c, width, height, base, colorPositive, colorNegative } = this.props;
    const { tooltip, tooltipTime, dot, dot2, vline } = this.refMap;
    const xBound = t.length - 1;

    let d;
    let snappedX;
    let snappedY;
    let dotColor = Colors.Default;

    // snap to base line
    if (index > xBound || index < 0 || snapToBaseline) {
      // eslint-disable-next-line
      snapToBaseline = true;
      d = base;
      snappedX = isSnapToEnd ? width - padding : padding;
      snappedY = getY(base);
      dot.setAttribute('fill', '#CCC');
      dot2.setAttribute('fill', 'rgba(200, 200, 200, 0.5)');
    } else {
      // snap to quote line
      d = c[index];
      const baseY = getY(base);

      snappedX = getX(t[index]);
      snappedY = getY(c[index]);
      if (snappedY < baseY) {
        dotColor = colorPositive;
      } else if (snappedY > baseY) {
        dotColor = colorNegative;
      }
      dot.setAttribute('fill', `rgb(${dotColor})`);
      dot2.setAttribute('fill', `rgba(${dotColor}, 0.2)`);
    }
    const sx = `${snappedX}`;
    const sy = `${snappedY}`;

    if (!this.mouseOver) {
      this.mouseOver = true;
      this.performHoverAnimation(1);
    }

    attr(vline, {
      x1: sx,
      x2: sx,
      y1: padding,
      y2: height,
    });

    // use transform so changes are committed in batch
    attr(dot, { transform: `translate(${sx}, ${sy})` });
    attr(dot2, { transform: `translate(${sx}, ${sy})` });

    vline.style.display = 'block';

    const time = snapToBaseline
      ? new Date((isSnapToEnd ? generator.maxX : generator.minX) * 1000)
      : new Date(t[snapToBaseline ? 0 : index] * 1000);

    tooltip.textContent = `${d}`;
    tooltipTime.textContent = tooltipByDate
      ? `[${pad2(time.getFullYear())}-${pad2(time.getMonth() + 1)}-${pad2(time.getDate())}]`
      : `[${pad2(time.getHours())}:${pad2(time.getMinutes())}]`;

    const timeBox = tooltipTime.getBBox();
    const txtBox = tooltip.getBBox();
    const paddingSize = padding * 3;

    const tooltipX =
      snappedX > width / 2
        ? snappedX - Math.max(timeBox.width, txtBox.width, 45) - paddingSize
        : snappedX + paddingSize;

    const fill = snapToBaseline ? '#AAA' : '#000';
    const sign = snappedY + padding * 2 > height - 20 ? -1 : 1;

    // commit changes to time and quote label
    [tooltip, tooltipTime].forEach((el, i) => {
      attr(el, {
        transform: `translate(${tooltipX}, ${snappedY + sign * (padding * 2 + i * 12)})`,
        fill,
      });
    });
  };

  render() {
    const { height, width, colorPositive, colorNegative, strokeWidth } = this.props;
    const fgColor = `rgba(${Colors.Default})`;
    const bgColor = `rgba(${Colors.Default}, 0.1)`;
    const upFgColor = `rgb(${colorPositive})`;
    const downFgColor = `rgb(${colorNegative})`;
    const upBgColor = `rgba(${colorPositive}, 0.1)`;
    const downBgColor = `rgba(${colorNegative}, 0.1)`;

    return (
      <svg
        className="simple-chart"
        height={height}
        width={width}
        viewBox={`0 0 ${width} ${height}`}
        ref={this.setSvg}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
      >
        <defs>
          <mask id={`maskUp-${this.uniqueId}`}>
            <rect ref={this.setRectUp} x="0" y="0" width={width} fill="white" />
          </mask>
          <mask id={`maskDown-${this.uniqueId}`}>
            <rect ref={this.setRectDown} x="0" y="0" width={width} fill="white" />
          </mask>
        </defs>
        <path ref={this.setBaseline} stroke="#DDD" strokeWidth="1" />
        <line ref={this.setVline} style={{ display: 'none' }} stroke="#DDD" />
        <path ref={this.setPlotUp} stroke={upFgColor} mask={`url(#maskUp-${this.uniqueId})`} fill="none" style={{strokeWidth}} />
        <path ref={this.setPlotDown} stroke={downFgColor} mask={`url(#maskDown-${this.uniqueId})`} fill="none" style={{strokeWidth}} />
        <path ref={this.setPlotAreaUp} fill={upBgColor} mask={`url(#maskUp-${this.uniqueId})`} style={{strokeWidth}} />
        <path ref={this.setPlotAreaDown} fill={downBgColor} mask={`url(#maskDown-${this.uniqueId})`} style={{strokeWidth}}/>
        <circle ref={this.setEndDot2} r="0" />
        <circle ref={this.setEndDot} r="0" strokeWidth="1" stroke="#FFF" />
        <circle ref={this.setDot2} r="0" fill={bgColor} />
        <circle ref={this.setDot} r="0" fill={fgColor} strokeWidth="1" stroke="#FFF" />
        <text ref={this.setTooltip} fill="black" strokeWidth="2" fontSize="12" />
        <text ref={this.setTooltipTime} fill="black" strokeWidth="2" fontSize="12" />
      </svg>
    );
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { wsApiClient } from './clients';

const removeUndefinedKeys = (object: { [x: string]: any }) => {
  const newObject: { [x: string]: any } = {};

  for (const key of Object.keys(object)) {
    if (object[key]) newObject[key] = object[key];
  }

  return newObject;
};

/**
 * @param {string} resolution 即時(1) / 分(5) /日(D) / 週(W) / 月(M)
 * @param {string} symbol 台股(TWS:0050:STOCK) / 台股興櫃(TWG:1260:STOCK) / 台股指數(TWS:TSE01:INDEX) / 陸股(CNS:601318:STOCK) / 港股(HKS:0700:STOCK) / 美股(USS:GOOGL:STOCK) / 國際指數(GI:SP500 ) / 期貨(GI:DJCIBR) / 外匯(FX:USDTWD)
 * @param {number} from timestamp
 * @param {number} to timestamp
 * @param {bool} quote api response include quote data or not
 * @param {bool} compress compress
 * @returns {Promise}
 */

export const GETchartingHistoryBySymbol = ({
  resolution,
  symbol,
  from,
  to,
  quote,
  compress
}: {
  resolution: string;
  symbol: string;
  from?: number;
  to?: number;
  quote?: string;
  compress?: number;
}) => {
  const url = '/ws/api/v1/charting/history';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {
    resolution,
    symbol,
    from,
    to,
    quote,
    compress
  };

  return wsApiClient.get(url, removeUndefinedKeys(params));
};

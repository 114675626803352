'use client';
/* eslint-disable import/no-extraneous-dependencies */
import { generateStockLinkBySymbol } from '@fe-common-utils/libs/link';
import { getStockFuture, getStockMarket } from '@fe-common-utils/libs/stock';
import { styled } from '@linaria/react';
import cx from 'classnames';
import throttle from 'lodash.throttle';
import { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SvgChart from './SvgChart/SvgChart';
import {
  getBaseline,
  getChartUpdateKey,
  getLastUpdateTimestamp,
  reverseHistory,
} from './helper';

import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import SmartTime from '../SmartTime/SmartTime';
import useFetchQuote from './useFetchQuote';

interface GtmEventType {
  [GTMEventKey.EVENT_NAME]: string;
  [GTMEventKey.SECTION]: CustomEventName.CLICK_BUTTON;
  [GTMEventKey.CLICK_ITEM]: string;
}

const RESIZE_THROTTLED_MS = 100;
const DELAY_TIME = 1000;

const screenSizeLarge = 1300;
const emptyValue = '--';
const defaultHeaderHeight = 58;
const defaultFooterHeight = 15;
const defaultMargin = 16;

const defaultChartInfo = {
  name: emptyValue,
  prevClosePrice: 0,
  session: [],
  value: emptyValue,
  netChange: emptyValue,
  changeInPercentage: emptyValue,
  volume: emptyValue,
  isTrading: false,
  marketStatus: 0
};

interface TrendChartProps {
  fullSize?: boolean;
  symbol: string;
  type?: string;
  showUpdateTime?: boolean;
  timezoneOffsetHour?: number;
  customName?: string;
  customChartSize?: {
    h: number;
    w: number;
  };
  gtmEvent?: GtmEventType;
  showVolume?: boolean;
}

const ChartContainer = styled.div`
  .trend-chart {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: white;

    &__header {
      width: 100%;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
      color: #383838;
      height: 18px;

      & > div {
        padding-right: 2px;

        &.empty {
          padding: 2px 10px;
        }
      }

      a {
        color: #383838;

        &:hover {
          color: #e03f19;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__col {
      &--empty {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 90%;
        height: 20px;
      }

      &--name {
        font-size: 13px;
        font-weight: 900;
        width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--down {
        color: rgb(32, 131, 6);
        padding: 2px;

        &.background {
          background-color: rgba(32, 131, 6, 0.1);
        }

        &.highlight {
          color: white;
          background-color: rgb(32, 131, 6);
        }
      }

      &--up {
        color: #e03f19;
        padding: 2px;

        &.background {
          background-color: rgba(224, 63, 25, 0.1);
        }

        &.highlight {
          color: white;
          background-color: #e03f19;
        }
      }

      &--equal {
        color: #383838;
        padding: 2px;

        &.background {
          background-color: rgba(56, 56, 56, 0.1);
        }
      }

      &--price {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
      }

      &--volume {
        color: #777777;
        padding: 2px;
        background-color: #f2f2f2;

        &.small {
          font-size: 12px;
        }
      }

      &--label {
        font-size: 13px;
        padding: 0;
        color: #777777;

        &.small {
          padding-top: 4px;
          font-size: 12px;
        }
      }
    }

    &__footer {
      height: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 6px 0 7px 0;

      &--time {
        font-family: Lato;
        font-size: 12px;
        color: #9b9b9b;
        margin-top: 5px;
      }

      > img {
        width: 30px;
        height: 12px;
      }
    }

    &__empty {
      padding-top: 54px;
      box-sizing: border-box;

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #e5e5e5;
        color: #9b9b9b;
        font-size: 13px;
        width: 100%;
        height: 100%;
      }
    }

    &__loading {
      height: 16px;
      width: 50%;

      &:last-child {
        padding-right: 0 !important;
      }

      &.chart {
        height: 54px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 6px;
      }

      &.time {
        height: 16px;
      }
    }

    @media only screen and (max-width: 320px) {
      &__header {
        width: 95%;
      }

      &__footer {
        width: 95%;
      }
    }
  }
`;

export const TrendChart = ({
  fullSize = false,
  symbol,
  type = 'response',
  showUpdateTime = false,
  showVolume = true,
  timezoneOffsetHour = 0,
  customName = '',
  customChartSize = { h: 75, w: 134 }
}: TrendChartProps) => {
  const [state, setState] = useState<{
    windowSize?: string;
    highlight: boolean;
    currentValue?: number;
  }>({
    windowSize: undefined,
    highlight: true,
    currentValue: undefined
  });

  const { history, quote, isHighlight } = useFetchQuote({ symbol });

  const handleOnClick = () => {
    if (quote) {
      const name = quote?.name ?? '';
      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_QUOTE,
        [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}_ChartQ`,
        [GTMEventKey.CLICK_ITEM]: `${symbol}_${name}`
      });
    }
  };

  const updateWindowSize = useCallback(() => {
    const newSize = window.innerWidth <= screenSizeLarge ? 'short' : 'large';

    setState(prevState => ({ ...prevState, windowSize: newSize }));
  }, []);

  const getChartSize = () => {
    switch (type) {
      case 'fixLarge':
        return {
          h: 55,
          w: 172
        };

      case 'fixMedium':
        return {
          h: 61,
          w: 149
        };

      case 'fixSmall':
        return {
          h: 55,
          w: 134
        };

      case 'custom':
        return {
          h: customChartSize.h,
          w: customChartSize.w
        };

      case 'response':
      default:
        return {
          h: 65,
          w: state.windowSize === 'large' ? 182 : 146
        };
    }
  };

  useEffect(() => {
    const handleResize = throttle(() => {
      updateWindowSize();
    }, RESIZE_THROTTLED_MS);

    if (type === 'response') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [type, updateWindowSize]);

  useEffect(() => {
    const updateTimeline = async () => {
      await new Promise(resolve => setTimeout(resolve, DELAY_TIME));
      setState(prev => ({ ...prev, highlight: false }));
    };

    updateTimeline();
  }, [isHighlight]);

  const renderLoading = () => {
    return (
      <Skeleton
        height={customChartSize.h + defaultHeaderHeight + defaultFooterHeight + defaultMargin}
        width={customChartSize.w}
      />
    );
  };

  const renderChart = (highlighted: boolean) => {
    const { h, w } = getChartSize();
    const { session, isTrading } = quote || defaultChartInfo;
    const { c, t } = reverseHistory({
      c: history?.c ?? [],
      t: history?.t ?? []
    });
    const base = getBaseline({ quote, c });
    const { lastUpdateTimestamp, lastPrice } = getLastUpdateTimestamp({ c, t });
    const chartUpdateKey = getChartUpdateKey({
      windowSize: state.windowSize,
      lastUpdateTimestamp,
      lastPrice,
      isTrading
    });

    return (
      <SvgChart
        updateKey={chartUpdateKey}
        height={h}
        width={w}
        padding={0}
        yGap={0}
        t={t}
        c={c}
        base={base}
        session={session}
        highlightLastPoint={highlighted}
        tooltipByDate={false}
      />
    );
  };

  const renderContent = (highlighted: boolean) => {
    const { name, value, netChange, changeInPercentage, prevClosePrice, volume } = quote || defaultChartInfo;
    const { c, t } = reverseHistory({
      c: history?.c ?? [],
      t: history?.t ?? []
    });
    const { lastUpdateTimestamp } = getLastUpdateTimestamp({ c, t });
    const { h, w } = getChartSize();
    // 清收盤的判斷
    const { session, marketStatus } = quote || defaultChartInfo;
    const now = Date.now();
    const sessionStart = session && session[0] && session[0][0];
    const isClean = marketStatus === 1;
    const isDelay = isClean && now >= sessionStart * 1000;
    const isEmpty = !quote;
    const emptyDivSize = { width: w, height: h + 10 };

    const signal = netChange && netChange[0];
    let signClassName = 'trend-chart__col--equal';

    if (!isEmpty) {
      if (signal === '+') {
        signClassName = 'trend-chart__col--up';
      } else if (signal === '-') {
        signClassName = 'trend-chart__col--down';
      }
    }
    const colClassName = cx(signClassName, {
      background: true,
      highlight: highlighted,
      empty: isEmpty
    });
    const link = generateStockLinkBySymbol(symbol);
    const displayName = customName || name;
    const updateTime =
      (showUpdateTime && lastUpdateTimestamp && lastUpdateTimestamp + timezoneOffsetHour * 60 * 60) || null;
    const displayVolume = showVolume && volume !== emptyValue;

    let volumeLabel = '成交量';
    let needReduceFontSize = false;
    let forexUnit = '';

    // 指數類型商品或虛擬貨幣改為"成交額";
    if (getStockFuture(symbol) === 'INDEX' || getStockMarket(symbol) === 'CC') {
      volumeLabel = '成交額';
      // 台幣兌換美金
      // 由於新台幣是來自於台北外匯中心的報價，因此揭露的成交額是有特殊意義的。
      // 而過往為每15分鐘揭露，但央行避免炒匯行為，改為上下午盤2筆報價，分別在12:00/16:00兩個時間點做累積報價
      // https://cnyesrd.atlassian.net/browse/ANUE-6129
    } else if (symbol === 'FX:USDTWD:FOREX') {
      if (t.length > 0) {
        const nowTime = t[t.length - 1];
        const today = new Date(nowTime * 1000);
        const day = +today.getDay();
        const hour = +today.getHours();
        const minute = +today.getMinutes();

        // 只需在星期一到星期五的 12 點和 16 點這中間驗證
        // 早上的累積額是在中午 12 點統計出來
        // 全日的累積額是在下午 16 點統計，但考慮後端的線圖分線，提前 5 分鐘做為判斷
        if ([1, 2, 3, 4, 5].includes(day)) {
          const time = hour * 100 + minute;
          const morning = 1200;
          const afternoon = 1555;

          if (time >= morning && time < afternoon) {
            volumeLabel = '上午成交額';
            // 其餘時間就顯示回"全日成交額"
          } else if (time >= afternoon) {
            volumeLabel = '全日成交額';
          }
          needReduceFontSize = true;
          forexUnit = volume !== emptyValue ? '美元' : '';
        }
      }
    }

    return (
      <>
        <div className='trend-chart__header'>
          <div className='trend-chart__row'>
            <div className='trend-chart__col--name'>
              {link ? (
                <a
                  href={link}
                  title={displayName}
                  data-recent-object-type='QUOTE'
                  data-recent-symbol={symbol}
                  onClick={handleOnClick}
                >
                  {displayName}
                </a>
              ) : (
                <span title={displayName}>{displayName}</span>
              )}
            </div>
            <div className={colClassName}>{changeInPercentage}</div>
          </div>
          <div className='trend-chart__row'>
            <div className={cx(signClassName, 'trend-chart__col--price')}>{value || prevClosePrice}</div>
            <div className={colClassName}>{netChange}</div>
          </div>
          {displayVolume && (
            <div className='trend-chart__row'>
              <div
                className={cx('trend-chart__col--label', {
                  small: needReduceFontSize
                })}
              >
                {volumeLabel}
              </div>
              <div
                className={cx('trend-chart__col--volume', {
                  small: needReduceFontSize
                })}
              >{`${volume}${forexUnit}`}</div>
            </div>
          )}
        </div>
        {isEmpty || isClean ? (
          <div className='trend-chart__empty' style={emptyDivSize}>
            <span>{isEmpty ? '' : isDelay ? '延遲報價' : '清盤'}</span>
          </div>
        ) : (
          renderChart(highlighted)
        )}
        <div className='trend-chart__footer'>
          <div className='trend-chart__footer--time'>
            {updateTime ? <SmartTime second={updateTime} format='MM/DD HH:mm' /> : ''}
          </div>
          <img src='/assets/icons/logo/anue-chart-logo.png' alt='anue' />
        </div>
      </>
    );
  };

  return (
    <ChartContainer
      style={{
        margin: fullSize ? '0px' : '8px'
      }}
    >
      {history ? renderContent(state.highlight) : renderLoading()}
    </ChartContainer>
  );
};
export default TrendChart;

import SvgChart, { Colors } from './SvgChart/SvgChart';
import TrendChart from './TrendChart';
import { getBaseline, getChartUpdateKey, getLastUpdateTimestamp, reverseHistory } from './helper';
import useFetchQuote, { fetchQuotesHistoryData } from './useFetchQuote';

export {
  Colors,
  SvgChart,
  TrendChart,
  fetchQuotesHistoryData,
  getBaseline,
  getChartUpdateKey,
  getLastUpdateTimestamp,
  reverseHistory,
  useFetchQuote
};

/**
 * *@TODO: 待補 test
 */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
export const CN_UNITS = ['萬', '億', '兆'];
export const US_UNITS = ['K', 'M', 'B', 'T'];

/**
 * 檢查傳入參數是否為數字
 *
 * @param {*} x - 任意值
 * @returns boolean
 */
export const isNumber = (x: unknown) => {
  return !Number.isNaN(x) && typeof x === 'number';
};

/**
 * 格式化傳入數值加上 +/- 符號
 *
 * @param {number} digit - 傳入數值
 * @param {Object} options - 額外附加參數
 * @param {boolean} [options.isPercentage=false] - 是否需要顯示百分比(%)符號
 * @returns {string} 帶有 +/- 符號的數字文字
 */
export const toSignedNumber = (digit: number, { isPercentage = false }) => {
  const percentage = isPercentage ? '%' : '';
  const num = `${digit}${percentage}`;

  return digit > 0 ? `+${num}` : `${num}`;
};

/**
 * 取得符合 en-US 格式的數值顯示字串
 *
 * @param {number} num - 傳入數值, ex: 30000.65
 * @returns {string} 格式化後數值, "30,000.65"
 */
export const toLocaleNumber = (num: {
  toLocaleString: (arg0: string, arg1: { maximumFractionDigits: number; minimumFractionDigits: number }) => never;
}) => {
  if (!isNumber(num)) {
    return num;
  }

  return num.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  });
};

/**
 * 四捨五入
 * 23445.567 -> 23445.57
 *
 * @param {number} val - 傳入數字
 * @param {number} [precision=2] - 四捨五入小數位數
 * @returns {string} 傳入數值四捨五入後的數字
 */
export const roundX = (val: number, precision = 2) => {
  if (!isNumber(val)) return NaN;

  return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, precision || 0);
};

/**
 * 依照傳入的參數取回相符的單位列表索引值
 *
 * @param {string} num - 傳入數值
 * @param {number} [divisor=4] - 數字進位的數量，ex: 10000 變成 1 萬的顯示時，位數需要進階 4 位
 * @param {string[]} [units=['萬', '億', '兆']] - 預設支援的單位列表
 * @returns {number} 得到數值單位中符合的索引值
 */
const getIndexOfUnits = (num: string, divisor = 4, units = CN_UNITS) => {
  const number = Math.abs(Number.parseFloat(num));
  const index = Math.floor((roundX(number, 0).toString().length - 1) / divisor);

  return index > units.length ? units.length : index;
};

/**
 * 依照傳入的數值取回相符的單位
 * ex: 12020000000 -> 億
 *
 * @param {number} parameter.num - 傳入數值
 * @param {number} [divisor=4] - 數字進位的數量，ex: 10000 變成 1 萬的顯示時，位數需要進階 4 位
 * @param {string[]} [units=['萬', '億', '兆']] - 預設支援的單位列表
 * @returns {string} 得到傳入數值符合的單位
 */
const getNumberUnit = (num: string, divisor = 4, units = CN_UNITS) => {
  const index = getIndexOfUnits(num, divisor, units);

  return units[index - 1] || '';
};

/**
 * 將傳入數字依照設定的進位數轉換後，得到對應的數值
 *  ex: 1234567.789 -> 123.45
 *
 * @param {number} num - 傳入數值
 * @param {number} [precision=2] - 四捨五入小數位數
 * @param {number} [divisor=4] - 數字進位的數量，ex: 10000 變成 1 萬的顯示時，位數需要進階 4 位
 * @param {string[]} [units=['萬', '億', '兆']] - 預設支援的單位列表
 * @returns {number} 得到進位後的數字
 */
export const numberCarry = (num: string, precision = 2, divisor = 4, units = CN_UNITS) => {
  const number = Number.parseFloat(num);
  const index = getIndexOfUnits(num, divisor, units);
  const divisorIndex = `1e${index * divisor}`;

  return roundX(number / (divisorIndex as unknown as number), precision);
};

/**
 * ex: 23445.567 -> 23,445.567
 * @param {string} num
 * @description 將整數位數三位補上',' 不另外做四捨五入。
 * @returns
 */
export const separatorNumber = (num: string) => {
  const [interger, deciaml] = num.toString().split('.');
  const sepInterger = interger.replace(/(\d{1,3})(?=(\d{3})+$)/g, '$1,');
  const tail = deciaml ? `.${deciaml}` : '';

  return `${sepInterger}${tail}`;
};

/**
 * 格式化數字為千分位
 * ex: 23445.567 -> 23,445.57
 *
 * @param {number} num - 傳入數值
 * @param {number} [precision=2] - 四捨五入小數位數
 * @returns {string} 傳入數值四捨五入後帶有千分位的數字
 */
export const formatNumber = (num: number, precision = 2) => {
  const arr = roundX(num, precision).toString().split('.');
  const integer = arr[0].replace(/(\d{1,3})(?=(\d{3})+$)/g, '$1,');
  const length = arr[1] == null ? precision : precision - arr[1].length;
  const decimal = Array(length).fill(0).join('').toString();
  const tail = arr[1] == null ? `.${decimal}` : arr[1].length < precision ? `.${arr[1]}${decimal}` : `.${arr[1]}`;

  return precision > 0 ? `${integer}${tail}` : integer;
};

/**
 * 將傳入數字轉換後，取得帶有對應單位的進位後數值
 * ex: 123456.789 -> 12.35萬
 * ex: 322543000000 -> 3,225.43億
 *
 * @param {number} num - 傳入數值
 * @param {string} [precision=2] - 四捨五入小數位數
 * @returns {string} 取得帶有對應單位的進位後數值
 */
export const formatNumberWithUnit = (num: string, precision = 2) => {
  if (!isNumber(num)) return num;

  const unit = getNumberUnit(num);
  const integer = Number.parseInt(num, 10);

  // 小於百萬時，仍以原始傳入值並去除小數位數，不進行處理
  if (Math.abs(integer) < 1000000) {
    return formatNumber(integer, 0);
  }

  const number = numberCarry(num, precision);
  const unitNum = formatNumber(number, precision);

  return `${unitNum}${unit}`;
};

/**
 * 取得目前傳入數字正負數
 *
 * @param {number} num - 傳入數值
 * @returns {number} 0: 表示傳入值為 0, 1: 表示正數, -1: 表示負數
 */
export const findNumberSign = (num: number) => {
  if (!isNumber(num)) return NaN;

  if (num < 0) {
    return -1;
  } else if (num > 0) {
    return 1;
  }

  return 0;
};

/**
 * 依照商品類別 mtype 不同，顯示基礎單位
 *
 * INDEX:
 * FUTURES: 口
 * STOCK: 張/股 (美股: 股，台股: 張)
 * FOREX:
 * EOD:
 */
export const findTradingVolumeUnit = (symbol: string) => {
  const symbolArrs = symbol.split(':');
  const stockType = symbolArrs[0] || '';
  const stockMeta = symbolArrs[2] || '';

  switch (stockMeta) {
    case 'FUTURES':
      return '口';
    case 'STOCK': {
      const checkType = ['USS', 'CNS', 'HKS'];

      return checkType.includes(stockType) ? '股' : '張';
    }
    default:
      return '';
  }
};

export default {
  isNumber,
  toSignedNumber,
  toLocaleNumber,
  roundX,
  numberCarry,
  formatNumber,
  formatNumberWithUnit,
  findNumberSign,
  findTradingVolumeUnit
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { QUOTES_CODE_MAPPING } from '@fe-common-utils/libs/quote';

const defaultChartData = {
  c: [],
  t: []
};

export const reverseHistory = ({ t, c }: { t: any; c: any }) => {
  if (!t || !c) {
    return defaultChartData;
  }
  const tmpC = [...c];
  const tmpT = [...t];

  return {
    c: tmpC.reverse(),
    t: tmpT.reverse()
  };
};

export const getBaseline = ({ quote, c }: { quote: any; c: any }) => {
  if (quote) {
    const prevClosePrice = quote.prevClosePrice || quote[QUOTES_CODE_MAPPING.LastClose];

    if (prevClosePrice || prevClosePrice === 0) {
      return prevClosePrice;
    }
  }

  if (c && c.length) {
    return c[0];
  }

  return 0;
};

export const getLastUpdateTimestamp = ({ t, c }: { t: any; c: any }) => {
  if (!t || !t.length || !c || !c.length) {
    return {
      lastUpdateTimestamp: null,
      lastPrice: null
    };
  }

  return {
    lastUpdateTimestamp: t[t.length - 1],
    lastPrice: c[c.length - 1]
  };
};

export const getChartUpdateKey = ({ windowSize, lastUpdateTimestamp, lastPrice, isTrading }: any) => {
  // set updateKey to falsy to prevent generate SVG
  if (!lastUpdateTimestamp) {
    return '';
  }

  const isWindowSizeLarge = windowSize === 'large';

  // repaint SVGChart when
  // 1. lastUpdateTimestamp change (received new data)
  // 2. lastPrice change
  // 3. window size change
  // 4. isTrading change
  return lastUpdateTimestamp + lastPrice + isWindowSizeLarge + !!isTrading;
};

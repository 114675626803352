/* eslint-disable import/no-extraneous-dependencies */
'use client';
import { findTradingVolumeUnit, formatNumberWithUnit } from '@fe-common-utils/libs/number';
import { QUOTES_CODE_MAPPING } from '@fe-common-utils/libs/quote';
import { useQuery } from '@tanstack/react-query';
import { GETchartingHistoryBySymbol } from 'libs/fe-common-ui/src/apis/quotes';
import { useEffect } from 'react';

const successStatusCode = 200;
const delayTime = 5 * 60 * 1000;

export const fetchQuotesHistoryData = async (symbol: string) => {
  try {
    const symbolSplit = String(symbol).split(':');
    const params: {
      symbol: string;
      resolution: string;
      quote: string;
      compress?: number;
      from?: number;
      to?: number;
    } = {
      symbol,
      resolution: '5', // forex 15分線, others 5分線, 參數固定傳 5 由 BE 依照狀況調整, https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/636747824
      quote: '1'
    };

    // TODO: crypto adjust
    if (symbolSplit[0] === 'CC') {
      const MS_PER_DAY = 1000 * 60 * 60 * 24;
      const toPHPTimestamp = (ts: number) => {
        return parseInt((ts / 1000) as unknown as string, 10);
      };
      const now = Date.now();
      const reqFrom = toPHPTimestamp(now);
      const reqTo = toPHPTimestamp(now - MS_PER_DAY);

      params.resolution = '1';
      params.compress = 1;
      params.from = reqFrom;
      params.to = reqTo;
    }

    // TODO: 目前先暫由前端處理外匯美元/台幣的分線處理，待後端完成處理再移除
    // 因為外匯是 15 分線(resolution 乘以 3)
    if (symbol === 'FX:USDTWD:FOREX') {
      params.resolution = '1';
    }

    const response = await GETchartingHistoryBySymbol(params);

    if (response.statusCode === successStatusCode) {
      const responseData = response?.data ?? [];
      return responseData;
    }

    return null;
  } catch (err) {
    console.error('fetch quote or history fail: ', symbol, err);
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formateQuoteData = (resp: any, symbol: string) => {
  let history;
  let quote;
  const symbolSplit = String(symbol).split(':');
  if (resp) {
    const { c, t, session, quote: quoteData } = resp;
    if (c && t) {
      history = {
        c,
        t
      };
    }
    if (quoteData && session) {
      // API 欄位: https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/633766431/API
      const {
        [QUOTES_CODE_MAPPING.ChineseName]: name,
        [QUOTES_CODE_MAPPING.NonFormattedChartQuote.LastClose]: prevClosePrice,
        [QUOTES_CODE_MAPPING.lastFormat]: value,
        [QUOTES_CODE_MAPPING.netChangeFormat]: netChange,
        [QUOTES_CODE_MAPPING.ChangePercent]: changeInPercentage,
        [QUOTES_CODE_MAPPING.MarketStatus]: marketStatus, // 0:收盤, 1:清盤, 2:開盤, 3:盤中暫停交易
        isTrading
      } = quoteData;
      let { [QUOTES_CODE_MAPPING.Volume]: volume } = quoteData;
      /**
       * [FE][ETF] 首頁QA
       * 市場總覽走勢小圖
       * 成交量單位:
       *   陸股/港股 手(100股)
       *   台股 張
       *   港/美股 股
       *
       *   陸股 成交量單位顯示為股, 所以拿到api資料為手,要再乘100
       */
      if (['CNS'].includes(symbolSplit[0])) {
        volume *= 100;
      }
      if (name) {
        quote = {
          symbol,
          name: symbolSplit[0] === 'CC' ? `${name} ${symbolSplit[1]}` : name,
          session,
          prevClosePrice,
          value: value || '--',
          netChange: netChange ? (netChange > 0 ? `+${netChange}` : netChange) : '--',
          changeInPercentage: changeInPercentage ? `${changeInPercentage}%` : '--',
          isTrading,
          marketStatus,
          // volume: volume ? `${formatNumberWithUnit(volume, 2)}${findTradingVolumeUnit(symbol)}` : emptyValue,
          // BOB 的需求: 千分位也都刪除，以免有些螢幕字體太小看起來像是小數點。
          volume: volume ? `${formatNumberWithUnit(volume, 2).replace(',', '')}${findTradingVolumeUnit(symbol)}` : '--'
        };
      }
    }
  }
  return {
    symbol,
    history,
    quote
  };
};

const useFetchQuote = ({ symbol }: { symbol: string }) => {
  const { data, refetch, isFetching } = useQuery({
    queryKey: ['quotes', symbol],
    queryFn: () => fetchQuotesHistoryData(symbol),
    select: quoteData => formateQuoteData(quoteData, symbol),
    initialData: { data: [] }
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      await refetch();
    }, delayTime);

    return () => {
      clearInterval(interval);
    };
  }, [symbol, refetch]);

  const { history, quote } = data || {};

  return { symbol, history, quote, isHighlight: isFetching };
};

export default useFetchQuote;
